import {
  Container,
  Typography,
  Card,
  CardMedia,
  CardContent,
  AppBar,
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Grid,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MenuIcon from "@mui/icons-material/Menu";
import ThemeChanger from "./ThemeChanger";
import Footer from "../sections/Footer";
import { useNavigate, useParams } from "react-router-dom";
import ButtonChip from "./ButtonChip";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import blogData from "../data/blogData.json";

const drawerWidth = 240;
const navItems = ["Home", "Services", "Gallery", "About", "Blogs"];

function ChangeAppBarColorOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    sx: {
      backgroundColor: trigger
        ? (theme) => theme.palette.primary.mainX
        : (theme) => theme.palette.primary.main,
      boxShadow: "rgba(60, 60, 60, 0.1) 0px 0px 10px 0px",
      transition: "background-color 0.3s ease",
    },
  });
}

ChangeAppBarColorOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const BlogDetails = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const { id } = useParams(); // Get the blog ID from the URL

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const navigate = useNavigate();

  const handleNavItemClick = (sectionId) => {
    navigate("/#" + sectionId); // Navigate to the homepage with the section ID as a hash
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 0); // Set a short timeout to ensure the navigation occurs first
  };

  const handleClick = () => {
    handleNavItemClick("contact");
  };

  useEffect(() => {
    const element = document.getElementById("top");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h4" sx={{ my: 2 }}>
        SOLUTIONS
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center", textTransform: "none" }}
              onClick={() => handleNavItemClick(item.toLowerCase())}
            >
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  // Find the blog with the matching ID
  const blog = blogData.find((blog) => blog.id.toString() === id);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ChangeAppBarColorOnScroll {...props}>
        <AppBar component="nav">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 1, display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>

            <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
              SOLUTIONS
            </Typography>

            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {navItems.map((item) => (
                <Typography
                  key={item}
                  variant="h5"
                  sx={{ mx: 2, color: "inherit", textTransform: "none" }}
                  component={Button}
                  onClick={() => handleNavItemClick(item.toLowerCase())}
                >
                  {item}
                </Typography>
              ))}
            </Box>

            <ButtonChip
              marginLeft={{ xs: 2, md: 0 }}
              label={"Contact Us"}
              onClick={handleClick}
            />
          </Toolbar>
        </AppBar>
      </ChangeAppBarColorOnScroll>

      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>

      <Box
        id="top"
        component="main"
        position={"relative"}
        overflow={"hidden"}
        sx={{ width: 1 }}
      >
        <Toolbar />
        <ThemeChanger />

        <Container
          maxWidth="xl"
          sx={{
            mb: 10,
            padding: 2,
          }}
        >
          <Typography
            variant="h2"
            align="center"
            mb={2}
            sx={{
              color: (theme) => theme.palette.primary.lightGreen,
              fontSize: {
                xs: "1.6rem",
                sm: "2rem",
                md: "2.2rem",
                lg: "2.6rem",
                xl: "3rem",
              },
              transition: "all 0.3s ease-in-out",
            }}
          >
            Our Blogs
          </Typography>

          <Typography
            variant="h5"
            align="center"
            gutterBottom
            sx={{
              width: {
                xs: "100%",
                sm: "80%",
                md: "70%",
                lg: "70%",
                xl: "50%",
              },
              mx: "auto",
              mb: 4,
            }}
          >
            Explore our latest blogs on technology, business, and design. Stay
            up to date with the latest trends and insights.
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              {blog ? (
                <Card key={blog.id} sx={{ mb: 4 }} variant="outlined">
                  <CardMedia
                    component="img"
                    height="280"
                    image={blog.image}
                    alt={blog.title}
                  />
                  <CardContent>
                    <Typography variant="h3" gutterBottom>
                      {blog.title}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                      By {blog.author} on {blog.date}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      {blog.intro}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      {blog.content.map((section, index) => (
                        <Box key={index} sx={{ mb: 2 }}>
                          <Typography variant="h4" gutterBottom>
                            {section.heading}
                          </Typography>
                          <Typography variant="h6">{section.text}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </CardContent>
                </Card>
              ) : (
                <Typography variant="h6">Blog not found</Typography>
              )}
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant="h4" mb={2}>
                Related Blogs
              </Typography>
              {blogData
                .filter((relatedBlog) => relatedBlog.id.toString() !== id)
                .map((relatedBlog) => (
                  <Card
                    variant="outlined"
                    key={relatedBlog.id}
                    sx={{ mb: 2, cursor: "pointer" }}
                    onClick={() => {
                      navigate(`/blog/${relatedBlog.id}`);
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="100"
                      image={relatedBlog.image}
                      alt={relatedBlog.title}
                    />
                    <CardContent>
                      <Typography variant="h5">{relatedBlog.title}</Typography>
                      <Typography variant="subtitle1" color="text.secondary">
                        By {relatedBlog.author} on {relatedBlog.date}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
            </Grid>
          </Grid>
        </Container>

        {/* Footer without padding */}
        <Footer sx={{ p: 0 }} />
      </Box>
    </Box>
  );
};

BlogDetails.propTypes = {
  window: PropTypes.func,
};

export default BlogDetails;
