import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ButtonChip from "./ButtonChip";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { useNavigate, useLocation } from "react-router-dom";

// Import section components
import Home from "../sections/Hero";
import Services from "../sections/Services";
import Gallery from "../sections/Gallery";
import About from "../sections/About";
import Blogs from "../sections/Blogs";
import ThemeChanger from "./ThemeChanger";
import Footer from "../sections/Footer";
import Contact from "../sections/Contact";

const drawerWidth = 240;
const navItems = ["Home", "Services", "Gallery", "About", "Blogs"];

function ChangeAppBarColorOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    sx: {
      backgroundColor: trigger
        ? (theme) => theme.palette.primary.mainX
        : (theme) => theme.palette.primary.main,
      boxShadow: "rgba(60, 60, 60, 0.1) 0px 0px 10px 0px",
      transition: "background-color 0.3s ease",
    },
  });
}

ChangeAppBarColorOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

function Layout(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  // Function to handle navigation and smooth scroll
  const handleNavItemClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      navigate("/#" + sectionId); // Update the URL with the hash
      section.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  // Use useEffect to handle the scroll on component mount if there's a hash in the URL
  useEffect(() => {
    if (location.hash) {
      const sectionId = location.hash.slice(1); // Get sectionId from the hash
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }, [location.hash]); // Run whenever the hash changes

  const handleClick = () => {
    handleNavItemClick("contact");
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h4" sx={{ my: 2 }}>
        SOLUTIONS
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center", textTransform: "none" }}
              onClick={() => handleNavItemClick(item.toLowerCase())}
            >
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ChangeAppBarColorOnScroll {...props}>
        <AppBar component="nav">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 1, display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>

            <Box sx={{ flexGrow: 1 }}>
              <img
                src="/images/logos/logo (2).png"
                alt="website logo"
               width={120}
              />
            </Box>

            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {navItems.map((item) => (
                <Typography
                  key={item}
                  variant="h5"
                  sx={{ mx: 2, color: "inherit", textTransform: "none" }}
                  component={Button}
                  onClick={() => handleNavItemClick(item.toLowerCase())}
                >
                  {item}
                </Typography>
              ))}
            </Box>

            <ButtonChip
              marginLeft={{ xs: 2, md: 0 }}
              label={"Contact Us"}
              onClick={handleClick}
            />
          </Toolbar>
        </AppBar>
      </ChangeAppBarColorOnScroll>

      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>

      <Box
        component="main"
        position={"relative"}
        overflow={"hidden"}
        sx={{ width: 1 }}
      >
        <Toolbar />
        <ThemeChanger />

        {/* Wrapper Box with padding for other sections */}
        <Box sx={{ p: 2 }}>
          <Home />
          <Services />
          <Gallery />
          <About />
          <Blogs />
          <Contact />
        </Box>

        {/* Footer without padding */}
        <Footer sx={{ p: 0 }} />
      </Box>
    </Box>
  );
}

Layout.propTypes = {
  window: PropTypes.func,
};

export default Layout;
