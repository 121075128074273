import { Box } from "@mui/material";
import React from "react";

const Shape = () => {
  return (
    <Box
      sx={{
        width: { xs: 250, sm: 340, md: 360 },
        height: { xs: 250, sm: 340, md: 360 },
        boxShadow: '0 0 25px rgba(0, 0, 0, 0.6)',
        filter: 'blur(35px)',
        backgroundColor: (theme) => theme.palette.primary.lightGreen,
        animation: "blob2 5s ease-in-out infinite",
        "@keyframes blob2": {
          "0%, 100%": {
            rotate: 0,
            borderRadius: "60% 40% 40% 70% / 60% 40% 70% 40%",
          },
          "50%": {
            rotate: 360,
            borderRadius: "40% 60% 70% 40% / 50% 60% 40% 60%",
          },
        },
      }}
    />
  );
};

export default Shape;
