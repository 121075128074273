import React, { useState } from "react";
import {
  Card,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import ButtonChip from "../components/ButtonChip";
import {
  AddLocationAlt,
  ContactMail,
  PhoneAndroid,
  Timer,
} from "@mui/icons-material";
import Shape from "../components/Shape";

const ContactCard = ({ icon: Icon, title, content }) => (
  <Card
    sx={{ p: 2, mb: 2, display: "flex", gap: 2, alignItems: "center" }}
    variant="outlined"
  >
    <IconButton sx={{ fontSize: "2rem" }}>
      <Icon />
    </IconButton>
    <Box>
      <Typography variant="h5" gutterBottom>
        <strong>{title}</strong>
      </Typography>
      <Typography variant="h5" gutterBottom>
        {content}
      </Typography>
    </Box>
  </Card>
);

const Contact = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    // Simulate form submission
    setTimeout(() => {
      setIsSubmitting(false);
      setIsSubmitted(true);
      // Reset form after 3 seconds
      setTimeout(() => setIsSubmitted(false), 3000);
    }, 2000);
  };
  return (
    <React.Fragment>
      <Container id="contact"
        maxWidth="xl"
        sx={{
          mb: 10,
          position: "relative",
        }}
      >
        <Typography
          variant="h2"
          align="center"
          mb={2}
          sx={{
            color: (theme) => theme.palette.primary.lightGreen,
            fontSize: {
              xs: "1.6rem",
              sm: "2rem",
              md: "2.2rem",
              lg: "2.6rem",
              xl: "3rem",
            },
            transition: "all 0.3s ease-in-out",
          }}
        >
          Contact Us
        </Typography>

        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            width: {
              xs: "100%",
              sm: "80%",
              md: "70%",
              lg: "70%",
              xl: "50%",
            },
            mx: "auto",
            mb: 4,
          }}
        >
          We are here to help you. Please fill out the form and we will get back
          to you as soon as possible.
        </Typography>

        <Box
          sx={{
            position: "absolute",
            top: "5%",
            left: "-20%",
            zIndex: -1,
          }}
        >
          <Shape />
        </Box>

        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={4}>
            <ContactCard
              icon={AddLocationAlt}
              title="Address:"
              content="1st Floor, 1699/167/A1, Road No. 1, Vidya Nagar, Harmu, Ranchi, Jharkhand, 834002"
            />
            <ContactCard
              icon={Timer}
              title="Office Hours:"
              content="Mon - Sat: 10:00 AM - 06:00 PM"
            />
            <ContactCard
              icon={PhoneAndroid}
              title="Phone:"
              content="+91-8767794712"
            />
            <ContactCard
              icon={ContactMail}
              title="Email:"
              content="aikyamsolutionsindia@gmail. com"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom>
              CONTACT FORM
            </Typography>
            <form>
              <TextField
                fullWidth
                margin="dense"
                placeholder="Name"
                variant="outlined"
              />
              <TextField
                fullWidth
                margin="dense"
                placeholder="Phone Number"
                variant="outlined"
              />
              <TextField
                fullWidth
                margin="dense"
                placeholder="Email address"
                variant="outlined"
              />
              <TextField
                fullWidth
                margin="dense"
                placeholder="Message"
                variant="outlined"
                multiline
                rows={4}
              />
              <Box mt={2}>
                <ButtonChip label="Send Message"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                />
                {isSubmitted && (
                  <Typography variant="h6" mt={2}>
                    Your message has been sent successfully. We will get back to
                    you soon.
                  </Typography>
                )}
              </Box>
            </form>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Contact;
