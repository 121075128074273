import React from "react";
import {
  Typography,
  CardContent,
  CardMedia,
  Container,
  Box,
  Card,
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

import services from "../data/services.json";

const Services = () => {
  return (
    <Container
      id="services"
      maxWidth="md"
      sx={{
        mb: 10,
        p: 0,
        width: "100%",
        maxWidth: {
          xl: "1440px",
        },
      }}
    >
      <Typography
        variant="h5"
        align="center"
        display={"flex"}
        gap={1}
        justifyContent={"center"}
        alignItems={"center"}
        mb={2}
      >
        What We're Offering <ArrowForward sx={{ color: "#00BCA3" }} />
      </Typography>

      <Typography
        variant="h2"
        align="center"
        gutterBottom
        sx={{
          color: (theme) => theme.palette.primary.lightGreen,
          fontSize: {
            xs: "1.6rem",
            sm: "2rem",
            md: "2.2rem",
            lg: "2.6rem",
            xl: "3rem",
          },
          transition: "all 0.3s ease-in-out",
          width: {
            xs: "100%",
            sm: "80%",
            md: "70%",
            lg: "70%",
            xl: "50%",
          },
          mx: "auto",
          mb: 4,
        }}
      >
        Services Built Specifically for your Business
      </Typography>

      <Box
        display="flex"
        flexWrap="wrap"
        gap={2}
        justifyContent="center"
        flexDirection={{ xs: "column", sm: "row", md: "row" }}
      >
        {services.map((service, index) => (
          <Box
            key={index}
            flexBasis={{
              xs: "100%",
              sm: "calc(50% - 16px)",
              md: "calc(50% - 16px)",
            }}
            display="flex"
            alignItems={index === 0 || index === 1 ? "flex-end" : "flex-start"}
            gap={1}
            component={Card}
            variant="outlined"
            elevation={0}
            flexDirection={"row"}
          >
            {index % 2 !== 0 && (
              <CardMedia
                component="img"
                image={service.image}
                alt={service.title}
                sx={{
                  overflow: "hidden",
                  objectFit: "cover",
                  flexBasis: "33.33%",
                  borderRadius:
                    index === 1
                      ? "0% 100% 100% 0% / 100% 100% 0% 0%"
                      : index === 3
                      ? "0% 100% 100% 0% / 0% 0% 100% 100%"
                      : undefined,
                }}
              />
            )}

            <Box flexBasis="66.67%">
              <CardContent>
                <Typography gutterBottom variant="h3" component="div">
                  {service.title}
                </Typography>
                <Typography variant="h6" sx={{ opacity: 0.8 }}>
                  {service.description}
                </Typography>
              </CardContent>
            </Box>

            {index % 2 === 0 && (
              <CardMedia
                component="img"
                image={service.image}
                alt={service.title}
                sx={{
                  overflow: "hidden",
                  objectFit: "cover",
                  flexBasis: "33.33%",
                  borderRadius: 
                      index === 0
                        ? "100% 0% 100% 0% / 100% 100% 0% 0%"
                        : index === 2
                        ? "0% 100% 0% 100% / 0% 0% 100% 100%"
                        : undefined,
                  
                }}
              />
            )}
          </Box>
        ))}

        <Box
          flexBasis="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={2}
        >
          <Typography
            variant="h5"
            sx={{
              opacity: 0.8,
              textAlign: { xs: "justify", sm: "center" },
              width: {
                xs: "100%",
                sm: "80%",
                md: "70%",
                lg: "70%",
                xl: "50%",
              },
              mx: "auto",
            }}
          >
            We provide a wide range of services that help you grow your business
            and reach new heights. Our services are designed to help you achieve
            your business goals and reach your target audience.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Services;
