import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Facebook, Instagram, LinkedIn, Twitter } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import ButtonChip from "../components/ButtonChip";
import Stack from "@mui/material/Stack";
import Shape from "../components/Shape";

const Footer = () => {
  return (
    <React.Fragment>
      <Container
        maxWidth="xl"
        sx={{
          pt: 5,
          position: "relative",
          overflow: "hidden",
  
        }}

    
      >

        <Grid container spacing={3} 
        >
          <Grid item xs={12} sm={4} md={3}>
            <Box
              component={"img"}
              src="/images/logos/logo (2).png"
              alt="logo"
              sx={{ width: 120 }}
            />
            {/* <Typography variant="h4" gutterBottom>
              About
            </Typography> */}
            <Typography variant="body1" gutterBottom>
              Aikyam Solutions is a leading IT company that provides end-to-end
              IT services and solutions to corporate and government clients.
            </Typography>
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <Typography variant="h4" gutterBottom>
              Services
            </Typography>
            <Link href="#" display="block" color={"inherit"} underline="none" gutterBottom>
              UI/UX Design
            </Link>
            <Link href="#" display="block" color={"inherit"} underline="none" gutterBottom>
              Business Automation
            </Link>
            <Link href="#" display="block" color={"inherit"} underline="none" gutterBottom>
              Digital Marketing
            </Link>
            <Link href="#" display="block" color={"inherit"} underline="none" gutterBottom>
              IT Services
            </Link>
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <Typography variant="h4" gutterBottom>
              Static Pages
            </Typography>
            <Link href="#" display="block" color={"inherit"} underline="none" gutterBottom>
              Terms & Conditions
            </Link>
            <Link href="#" display="block" color={"inherit"} underline="none" gutterBottom>
              Privacy Policy
            </Link>
            <Link href="#" display="block" color={"inherit"} underline="none" gutterBottom>
              SiteMap
            </Link>
            <Link href="#" display="block" color={"inherit"} underline="none" gutterBottom>
              Contact Us
            </Link>
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <Typography variant="h4" gutterBottom>
              Quick Links
            </Typography>
            <Link href="#" display="block" color={"inherit"} underline="none" gutterBottom>
              About Us
            </Link>
            <Link href="#" display="block" color={"inherit"} underline="none" gutterBottom>
              Services
            </Link>
            <Link href="#" display="block" color={"inherit"} underline="none" gutterBottom>
              Gallery
            </Link>
            <Link href="#" display="block" color={"inherit"} underline="none" gutterBottom>
              Blogs
            </Link>
          </Grid>

          <Grid item xs={6} sm={4} md={3}>
            <Typography variant="h4" gutterBottom>
              Follow Us
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                flexDirection: "column",
              }}
            >
              <div>
                <IconButton color="inherit">
                  <Facebook />
                </IconButton>
                <IconButton color="inherit">
                  <Instagram />
                </IconButton>
                <IconButton color="inherit">
                  <Twitter />
                </IconButton>
                <IconButton color="inherit">
                  <LinkedIn />
                </IconButton>
              </div>

              <Stack
                direction="row"
                spacing={2}
                border={1}
                borderColor="#858585"
                borderRadius={50}
                p={0.5}
                pl={2}
                justifyContent="flex-end"
                visibility={{xs: "hidden", md: "visible"}}
              >
                <InputBase
                  placeholder="Email Address"
                  inputProps={{ "aria-label": "search" }}
                  color="inherit"
                />
                <ButtonChip label="Subscribe" />
              </Stack>
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: {xl: "none", md: "flex"},
            position: "absolute",
            top: "5%",
            right: "-20%",
            zIndex: -1,
          }}
        >
          <Shape />
        </Box>

        <Typography
          variant="body1"
          align="center"
          color="textSecondary"
          sx={{
            borderTop: "1px solid rgba(0, 0, 0, 0.12)",
            py: 2,
            marginTop: 2,
          }}
        >
          © 2024 Aikyam Solutions. All rights reserved.
        </Typography>

      </Container>
    </React.Fragment>
  );
};

export default Footer;
