import React, { useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  IconButton,
  Container,
  Paper,
  Stack,
} from "@mui/material";
import {
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
  ChevronLeft,
  ChevronRight,
} from "@mui/icons-material";
import Shape from "../components/Shape";

import teamMembersData from "../data/teamsData.json";


const iconMap = {
  "<LinkedIn />": LinkedIn,
  "<Instagram />": Instagram,
  "<Facebook />": Facebook,
  "<Twitter />": Twitter,
};

const TeamMember = ({ name, role, image, socialLinks, altText }) => (
  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
    <Box sx={{ display: "flex", position: "relative" }}>
      <Paper
        elevation={6}
        sx={{
          display: { xs: "none", sm: "flex" },
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          padding: 0.6,
          width: 150,
          zIndex: 1,
          margin: "15px -15px auto 0",
          borderLeft: (theme) =>
            `4px solid ${theme.palette.primary.lightGreen}`,
        }}
      >
        <Typography variant="h6" sx={{ ml: 0.5 }}>
          {name}
        </Typography>
      </Paper>

      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Stack
          direction="column"
          spacing={1}
          sx={{
            position: "absolute",
            top: 0,
            left: -20,
            zIndex: 1,
            padding: 0.6,
            background: (theme) => theme.palette.primary.lightGreen,
            borderRadius: "0 20px 0 20px",
            display: { xs: "flex", sm: "none" },
          }}
        >
         {socialLinks.map((link, index) => {
            const Icon = iconMap[link.icon];
            return (
              <IconButton
                key={index}
                href={link.url}
                target="_blank"
                size="small"
                sx={{ color: "white" }}
              >
                <Icon />
              </IconButton>
            );
          })}
        </Stack>
        <Box
          component="img"
          src={image}
          alt={altText}
          sx={{
            width: { xs: 250, sm: 340, md: 360 },
            height: { xs: 250, sm: 340, md: 360 },
            padding: 1,
            border: (theme) => `2px solid ${theme.palette.primary.lightGreen}`,
            animation: "blob 5s ease-in-out infinite",
            "@keyframes blob": {
              "0%, 100%": {
                borderRadius: "60% 40% 40% 70% / 60% 40% 70% 40%",
              },
              "50%": {
                borderRadius: "40% 60% 70% 40% / 50% 60% 40% 60%",
              },
            },
          }}
        />

        <Stack
          mt={3}
          direction="column"
          spacing={0.5}
          display={{ xs: "flex", sm: "none" }}
          alignItems="center"
        >
          <Typography variant="h5">{name}</Typography>
          <Typography variant="h6">{role}</Typography>
        </Stack>
      </Box>

      <Paper
        elevation={6}
        sx={{
          display: { xs: "none", sm: "flex" },
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 0.6,
          width: 160,
          margin: "auto 0 15px -15px",

          borderRight: (theme) =>
            `4px solid ${theme.palette.primary.lightGreen}`,
        }}
      >
        <Typography variant="h6" align="center" gutterBottom>
          {role}
        </Typography>

        <Stack direction="row" spacing={1}>
          {socialLinks.map((link, index) => {
            const Icon = iconMap[link.icon];
            return (
              <IconButton
                key={index}
                href={link.url}
                target="_blank"
                size="small"
                color="inherit"
              >
                <Icon />
              </IconButton>
            );
          })}
        </Stack>
      </Paper>
    </Box>
  </Box>
);

const Teams = () => {
  const [slideIndex, setSlideIndex] = useState(0);



  const handlePrevSlide = () => {
    setSlideIndex((prevIndex) =>
      prevIndex === 0 ? teamMembersData.length - 1 : prevIndex - 1
    );
  };

  const handleNextSlide = () => {
    setSlideIndex((prevIndex) =>
      prevIndex === teamMembersData.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <React.Fragment>
      <Container  id="about"
        maxWidth="xl"
        sx={{
          mb: 10,
          position: "relative",
        }}
      >
        <Box
          sx={{
            
            position: "absolute",
            top: "-5%",
            right: { xs: "-60%", sm: "-30", md: "-20%"},
            zIndex: -1,
          }}
        >
          <Shape />
        </Box>

        <Typography
          variant="h2"
          align="center"
          mb={2}
          sx={{
            color: (theme) => theme.palette.primary.lightGreen,
            fontSize: {
              xs: "1.6rem",
              sm: "2rem",
              md: "2.2rem",
              lg: "2.6rem",
              xl: "3rem",
            },
            transition: "all 0.3s ease-in-out",
          }}
        >
          Our Team
        </Typography>

        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            width: {
              xs: "100%",
              sm: "80%",
              md: "70%",
              lg: "70%",
              xl: "50%",
            },
            mx: "auto",
            mb: 4,
          }}
        >
          Meet the talented individuals who make our company what it is, with
          their unique skills and expertise.
        </Typography>

        <Box sx={{ position: "relative" }}>
          <TeamMember {...teamMembersData[slideIndex]} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              position: "absolute",
              top: "50%",
              left: -20,
              right: -20,
            }}
          >
            <IconButton onClick={handlePrevSlide}>
              <ChevronLeft fontSize="large" />
            </IconButton>
            <IconButton onClick={handleNextSlide}>
              <ChevronRight fontSize="large" />
            </IconButton>
          </Box>
        </Box>
        <Typography
          variant="h5"
          align="center"
          sx={{
            mt: { xs: 1, sm: 4 },
            opacity: 0.8,
          }}
        >
          {teamMembersData[slideIndex].caption}
        </Typography>

        <Box
          sx={{
            width: "100%",
            overflowX: "auto",
            scrollbarWidth: "thin",
            py: 2,
            display: "flex",
            justifyContent: {
              xs: "flex-start",
              sm: "flex-start",
              md: "center",
            },
            mt: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 2,
              minWidth: "max-content",
            }}
          >
            {teamMembersData.map((member, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  padding: 0.5,
                  borderRadius: 50,
                  border:
                    index === slideIndex
                      ? (theme) =>
                          `2px dashed ${theme.palette.primary.lightGreen}`
                      : "2px dashed transparent",
                  transition: "all 0.3s ease-in-out",
                  cursor: "pointer",
                  "&:hover": {
                    border: (theme) =>
                      `2px dashed ${theme.palette.primary.lightGreen}`,
                  },
                }}
                onClick={() => setSlideIndex(index)}
              >
                <Avatar
                  sx={{
                    width: 100,
                    height: 100,
                  }}
                  src={member.image}
                  alt={member.altText}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Teams;
