import { createContext, useState, useMemo, useEffect } from "react";
import { createTheme } from "@mui/material/styles";


// mui theme settings
export const themeSettings = (mode) => {
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: "#0C0C0C50",
              mainX: "#0C0C0C",
              lightGreen: "#00BCA3",
              darklittle: "#232323",
              headings: "#f5f5f5",
            },
            background: {
              default: "#0D1117",
            },
          }
          : {
            // palette values for light mode
            primary: {
              main: "#ffffff50",
              mainX: "#ffffff",
              lightGreen: "#00BCA3",
              darklittle: "#f5f5f5",
              headings: "#454545",
            },
            background: {
              default: "#ffffff",
            },
          }),
    },
    typography: {
      fontFamily: ["Red Rose", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Red Rose", "sans-serif"].join(","),
        fontSize: 48,
        fontWeight: 700,
      },
      h2: {
        fontFamily: ["Red Rose", "sans-serif"].join(","),
        fontSize: 32,
        fontWeight: 500,
      },
      h3: {
        fontFamily: ["Red Rose", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Red Rose", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Red Rose", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Red Rose", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  // Retrieve mode from local storage or default to "light"
  const storedMode = localStorage.getItem("mode") || "light";
  const [mode, setMode] = useState(storedMode);

  useEffect(() => {
    // Update local storage whenever mode changes
    localStorage.setItem("mode", mode);
  }, [mode]);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prev) => (prev === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode];
};
