import React from 'react';
import { Box } from '@mui/material';
import { keyframes } from '@mui/system';

// Define the keyframes using MUI's `keyframes` utility
const fluidAnimation = keyframes`
  0% {
    rotate: 0;
    border-radius: 70% 30% 30% 70% / 60% 30% 70% 40% ;
  }
  25% {
    rotate: 30deg;
    border-radius: 55% 45% 30% 65% / 55% 35% 40% 60%;
  }
  50% {
    rotate: 90deg;
    border-radius: 45% 65% 65% 35% / 45% 50% 55% 65%;
  }
  75% {
    rotate: 270deg;
    border-radius: 35% 60% 50% 65% / 45% 55% 40% 70%;
  }
  100% {
    rotate: 0;
    border-radius: 70% 30% 30% 70% / 60% 30% 70% 40% ;
  }
`;

const ShapeRight = () => {
  return (
    <Box
      sx={{
        width: '460px',
        height: '460px',
        backgroundColor: (theme) => theme.palette.primary.lightGreen,
        position: 'absolute',
        top: '-1%',
        right: '-5%',
        boxShadow: '0 0 25px rgba(0, 0, 0, 0.6)',
        filter: 'blur(35px)',
        animation: `${fluidAnimation} 6s infinite ease-in-out`,
        zIndex: -1,
      }}
    />
  );
};

export default ShapeRight;
