import React from 'react'
import CoreValue from './CoreValue'
import Teams from './Teams'
import { Container } from '@mui/material'
import WorkArea from '../components/WorkArea'

const About = () => {
  return (
    <React.Fragment>
    <Container
      maxWidth="xl"
      sx={{
        mb: 10,
      }}
    >
      <Teams />
      <CoreValue />
      <WorkArea />
      </Container>
    </React.Fragment>
  )
}

export default About
