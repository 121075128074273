import React, { useState } from "react";
import { Container, Grid, Stack, Typography, Box } from "@mui/material";

const CoreValue = () => {
  const coreValues = [
    {
      title: "AIKYAM कुटुम्ब",
      eng_title: "One Family",
      description:
        "It refers to the idea of unity, interconnectedness, and a shared sense of belonging among all human beings.",
    },
    {
      title: "AIKYAM कर्म",
      eng_title: "Work Come First",
      description:
        "It is a phrase that underscores the importance of prioritizing one's professional responsibilities and commitments.",
    },
    {
      title: "AIKYAM श्रेष्ठ",
      eng_title: "Unmatched Excellence",
      description:
        "It is also a phrase that conveys the idea of something being so exceptionally flawless and outstanding that it surpasses.",
    },
    {
      title: "AIKYAM सत्य",
      eng_title: "One Truth",
      description:
        "It is a principle that underscores the importance of openness, honesty, and clear communication as a means to uncover and uphold.",
    },
  ];

  return (
    <React.Fragment>
      <Container
        maxWidth="xl"
        sx={{
          mb: 10,
        }}
      >
        <Typography
          variant="h2"
          align="center"
          mb={2}
          sx={{
            color: (theme) => theme.palette.primary.lightGreen,
            fontSize: {
              xs: "1.6rem",
              sm: "2rem",
              md: "2.2rem",
              lg: "2.6rem",
              xl: "3rem",
            },
            transition: "all 0.3s ease-in-out",
          }}
        >
          Core Values
        </Typography>

        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            width: {
              xs: "100%",
              sm: "80%",
              md: "70%",
              lg: "70%",
              xl: "50%",
            },
            mx: "auto",
            mb: 4,
          }}
        >
          Our core values are the guiding principles that dictate our behavior
          and actions. They are the foundation of our company culture.
        </Typography>

        <Stack spacing={2}>
          {coreValues.map((value, index) => (
            <Grid
              container
              spacing={2}
              key={index}
              sx={{
                border: 1,
                borderColor: (theme) => theme.palette.primary.lightGreen,
                borderRadius: 2,
                overflow: "hidden",
             
              }}
            >
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.lightGreen,
                  color: (theme) => theme.palette.primary.darklittle,
                  fontSize: {
                    xs: "1.4rem",
                    sm: "1.6rem",
                    md: "1.8rem",
                    lg: "2rem",
                    xl: "2.2rem",
                  },
                  fontWeight: "bold",
                  transition: "all 0.3s ease-in-out",
                  position: "relative",
                  overflow: "hidden",
                  pb: 2.5,
                  pr: 3,
                  cursor: "pointer",
                }}
              >
                <HoverTitle title={value.title} engTitle={value.eng_title} />
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                sx={{
                  fontSize: {
                    xs: "0.9rem",
                    sm: "1rem",
                    md: "1.1rem",
                    lg: "1.2rem",
                    xl: "1.3rem",
                  },
                  textAlign: "justify",
                  transition: "all 0.3s ease-in-out",
                  pb: 2.5,
                  pr: 3,
                }}
              >
                {value.description}
              </Grid>
            </Grid>
          ))}
        </Stack>
      </Container>
    </React.Fragment>
  );
};

const HoverTitle = ({ title, engTitle }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
        py: 2,
        px: 2,
      }}
    >
      <Box
        sx={{
          top: 0,
          left: 0,
          position: "absolute",
          transition: "all 0.3s ease-in-out",
          transform: isHovered ? "translateY(-100%)" : "translateY(0)",
        }}
      >
        {title}
      </Box>
      <Box
        sx={{
          top: 0,
          left: 0,
          position: "absolute",
          transition: "all 0.3s ease-in-out",
          transform: isHovered ? "translateY(0)" : "translateY(100%)",
        }}
      >
        {engTitle}
      </Box>
    </Box>
  );
};

export default CoreValue;
