import React, { useContext, useState } from "react";
import { ColorModeContext } from "../theme";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Box } from "@mui/material";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import NightsStayIcon from "@mui/icons-material/NightsStay";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.lightgreen,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor:"#454545",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const ThemeChanger = () => {
  const { toggleColorMode } = useContext(ColorModeContext);

  // get the current mode from local storage
  const storedMode = localStorage.getItem("mode") || "light";

  const [checked, setChecked] = useState(storedMode === "dark");

  const handleChange = (event) => {
    setChecked(event.target.checked);
    toggleColorMode();
  };

  return (
    <Box
      sx={{
        position: "fixed",
        zIndex: 100,
        top: "10%",
        right: "2%",
        display: "flex",
      }}
    >
      <FormGroup>
        <FormControlLabel
          control={
            <IOSSwitch
              sx={{ m: 1 }}
              checked={checked}
              onChange={handleChange}
              icon={<NightsStayIcon />}
              checkedIcon={<WbSunnyIcon />}
            />
          }
          label=""
        />
      </FormGroup>
    </Box>
  );
};

export default ThemeChanger;
