import React from "react";
import Chip from "@mui/material/Chip";

const ButtonChip = ({ label, onClick, marginLeft, icon, marginTop, disabled}) => {
  return (
    <>
      <Chip
      icon={icon}
        label={label}
        clickable
        onClick={onClick}
        disabled={disabled}
        sx={{
          fontSize: "1rem",
          backgroundColor: (theme) => theme.palette.primary.lightGreen,
          color: (theme) => theme.palette.primary.darklittle,
          marginLeft: marginLeft,
          marginTop: marginTop,
          '&:hover': {
            backgroundColor: "#009985",
          }
        }}
      />
    </>
  );
};

export default ButtonChip;
