import { Box, Dialog } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { RemoveRedEye } from "@mui/icons-material";
import { Button, IconButton, Stack } from "@mui/material";
import { Close } from "@mui/icons-material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

const styleToDialogContent = {
  padding: "0",
  backgroundColor: "#232323",
  scrollbarColor: "#505050 #232323",
  scrollbarWidth: "thin",
  //add shadow to dialog content
  boxShadow: "0px 0px 10px 0px rgba(200,200,200,0.7)",
};

const Gallery = () => {
  const [selectedImage, setSelectedImage] = React.useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const images = [
    {
      id: 10,
      src: "images/gallery-img/jfta.webp",
      alt: "JFTA Acting School",
      link: "/sites/jfta_site/index.html",
    },
    {
      id: 3,
      src: "images/gallery-img/charity.webp",
      alt: "NGO",
      link: "/sites/lovecare/index.html",
    },
    {
      id: 1,
      src: "images/gallery-img/Ghar.webp",
      alt: "Real Estate",
      link: "/sites/homeline/index.html",
    },
    {
      id: 2,
      src: "images/gallery-img/FitBuddy.webp",
      alt: "Fitness",
      link: "/sites/fitbuddy/index.html",
    },
    {
      id: 7,
      src: "images/gallery-img/Digital Marketing.webp",
      alt: "Sales & Services",
      link: "#",
    },
    {
      id: 5,
      src: "images/gallery-img/FoodFreak.webp",
      alt: "Food App",
      link: "#",
    },
    {
      id: 9,
      src: "images/gallery-img/WTF.webp",
      alt: "AutoVerTest",
      link: "#",
    },
    {
      id: 8,
      src: "images/gallery-img/Mobile Design - Tutorial App.webp",
      alt: "Tutorial App",
      link: "#",
    },
    {
      id: 4,
      src: "images/gallery-img/hospitals.webp",
      alt: "Hospital App",
      link: "#",
    },
    {
      id: 6,
      src: "images/gallery-img/Restobar.webp",
      alt: "Restaurant",
      link: "/sites/restobar/index.html",
    },
  ];

  var settings = {
    autoplay: true,
    lazyLoad: true,
    arrows: true,
    cssEase: "linear",
    centerMode: true,
    centerPadding: "60px",
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],

    appendDots: (dots) => (
      <Box
        sx={{
          "& .slick-active button": {
            backgroundColor: "#00BCA3 !important",
          },
          "& button": {
            borderRadius: "50%",
          },
          "& ul": {
            display: "flex",
            justifyContent: "center",
            listStyle: "none",
            padding: 0,
          },
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </Box>
    ),
  };

  return (
    <React.Fragment>
      <Container id="gallery"
        maxWidth="xl"
        sx={{
          mb: 10,
        }}
      >
        <Typography
          variant="h2"
          align="center"
          mb={2}
          sx={{
            color: (theme) => theme.palette.primary.lightGreen,
            fontSize: {
              xs: "1.6rem",
              sm: "2rem",
              md: "2.2rem",
              lg: "2.6rem",
              xl: "3rem",
            },
            transition: "all 0.3s ease-in-out",
          }}
        >
          Sample Works
        </Typography>

        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            width: {
              xs: "100%",
              sm: "80%",
              md: "70%",
              lg: "70%",
              xl: "50%",
            },
            mx: "auto",
            mb: 4,
          }}
        >
          Here are some of the projects we have worked on. Click on the images
          to view the live site.
        </Typography>

        <div className="slider-container">
          <Slider {...settings}>
            {images.map((image) => (
              <Box
                key={image.id}
                className="slider-item"
                sx={{
                  position: "relative",
                  display: "flex",
                  padding: { xs: "10px", sm: "20px" },
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  overflow: "hidden",
                  borderRadius: "10px",
                  "&:hover": {
                    border: "1px solid #00BCA3",
                  },
                }}
              >
              
                  <Box
                  onClick={() => openModal(image)}
                    sx={{
                      cursor: "pointer",
                      position: "relative",
                      width: "100%",
                      height: {
                        xs: "200px",
                        sm: "250px",
                        md: "300px",
                        lg: "400px",
                        xl: "420px",
                      },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                      "& img": {
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        objectPosition: "top",
                        transition: "transform 0.3s",
                        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                        "&:hover": {
                          transform: "translateY(10px)",
                        },
                      },
                      "&:hover .overlay": {
                        opacity: 1,
                      },
                      "&:hover .button-container": {
                        opacity: 1,
                      },
                    }}
                  >
                    <img src={image.src} alt={image.alt} />
                    <Box
                      className="overlay"
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: (theme) =>
                          theme.palette.primary.lightGreen,
                        color: "#fff",
                        padding: "10px",
                        textAlign: "center",
                        opacity: 0,
                        transition: "opacity 0.3s",
                        fontSize: {
                          xs: "1rem",
                          sm: "1.2rem",
                          md: "1.4rem",
                          lg: "1.6rem",
                          xl: "1.8rem",
                        },
                      }}
                    >
                      {image.alt}
                    </Box>
                    <Box
                      className="button-container"
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        opacity: 0,
                        transition: "opacity 0.3s",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        "& svg": {
                          color: (theme) => theme.palette.primary.lightGreen,
                          fontSize: "2.4rem",
                          transition: "all 0.3s",
                        },
                      }}
                    >
                      <RemoveRedEye />
                    </Box>
                  </Box>
               
              </Box>
            ))}
          </Slider>
        </div>
      </Container>

      <Dialog open={!!selectedImage} fullWidth={true} maxWidth="xl">
        <DialogTitle>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h4" sx={{ fontWeight: 600 }} noWrap>
              {" "}
              {selectedImage?.alt}{" "}
            </Typography>

            <Stack direction="row" spacing={2}>
              <Button
                onClick={() => window.open(selectedImage?.link, "_blank")}
                variant="contained"
                disableElevation
                startIcon={<RemoveRedEye />}
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.lightGreen,
                  color: (theme) => theme.palette.primary.darklittle,
                  "&:hover": {
                    backgroundColor: "#009985",
                  },
                }}
              >
                Live
                <Box
                  sx={{
                    display: { xs: "none", md: "inline-block" },
                    marginLeft: "5px",
                  }}
                >
                  Preview
                </Box>
              </Button>
              <IconButton
                onClick={closeModal}

                style={{
                  borderRadius: "50%",
                  backgroundColor: "#454545",
                  color: "#fff",
                }}
              >
                <Close />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ ...styleToDialogContent }}>
          <img
            src={selectedImage?.src}
            alt={selectedImage?.alt}
            style={{ width: "100%" }}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default Gallery;
