import React, { useEffect, useRef } from "react";
import { Box, Container, InputBase, Stack, Typography } from "@mui/material";
import { gsap } from "gsap";
import ShapeLeft from "../components/ShapeLeft";
import ShapeRight from "../components/ShapeRight";
import ButtonChip from "../components/ButtonChip";
import Robot from "../components/Robot";

const Hero = () => {
  const counterRefs = {
    reachOut: useRef(null),
    designs: useRef(null),
    loves: useRef(null),
  };

  useEffect(() => {
    const animateCounter = (ref, endValue) => {
      gsap.fromTo(
        ref.current,
        { innerText: 0 },
        {
          innerText: endValue,
          duration: 2,
          ease: "power4.out",
          snap: { innerText: 1 },
          stagger: 0.2,
          onUpdate: () => {
            ref.current.innerText = `${Math.ceil(ref.current.innerText)}+`;
          },
        }
      );
    };

    Object.entries(counterRefs).forEach(([key, ref]) => {
      animateCounter(
        ref,
        key === "reachOut" ? 500 : key === "designs" ? 100 : 250
      );
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const commonTypographyProps = {
    color: (theme) => theme.palette.primary.headings,
    textAlign: { xs: "center", sm: "left" },
    transition: " all 0.3s ease-in-out",
  };

  const renderCounter = (label, ref) => (
    <div>
      <Typography ref={ref} variant="h2">
        0
      </Typography>
      <Typography variant="h6">{label}</Typography>
    </div>
  );

  return (
    <Container id="home"
      maxWidth="lg"
      sx={{
        mb: 10,
        width: "100%",
        maxWidth: {
          xl: "1440px",
        },
        transition: " all 0.3s ease-in-out",
      }}
    >
      <ShapeLeft />
      <ShapeRight />

      <Stack
        direction={{ xs: "column-reverse", sm: "row" }}
        alignItems={{ xs: "center", sm: "flex-start" }}
        justifyContent="space-between"
        spacing={2}
        position="relative"
      >
        <Box
          sx={{
            transition: " all 0.3s ease-in-out",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 1,
            pt: { xs: 0, sm: 8, md: 10 },
            ...commonTypographyProps,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              transition: " all 0.3s ease-in-out",
              width: "100%",
              fontSize: {
                xs: "1rem",
                sm: "1.1rem",
                md: "1.2rem",
                xl: "1.3rem",
              },
            }}
            gutterBottom
          >
            Bring Your Business Online With Us
          </Typography>
          <Typography
            variant="h1"
            sx={{
              fontSize: {
                xs: "2.2rem",
                sm: "2.5rem",
                md: "3.2rem",
                xl: "4rem",
              },
              transition: " all 0.3s ease-in-out",
            }}
            gutterBottom
          >
            Revolutionizing The <br />
            Ideas To Reality
          </Typography>

          <Stack
            direction="row"
            spacing={2}
            border={1}
            borderColor="#858585"
            borderRadius={50}
            p={0.5}
            pl={2}
              justifyContent="flex-end"
          >
            <InputBase
              placeholder="Email Address"
              inputProps={{ "aria-label": "search" }}
              color="inherit"
            />
            <ButtonChip label="Let's Connect" />
          </Stack>
        </Box>

        <Box sx={{ display: "flex" }}>
          <Robot />
        </Box>
      </Stack>

      <Stack
        direction={{ xs: "column-reverse", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "center", sm: "flex-start" }}
        spacing={3}
        mt={{ xs: 3, sm: 3, md: 3, lg: 0 }}
      >
        <Stack
          direction="column"
          spacing={2}
          alignItems={{ xs: "center", sm: "flex-start" }}
        >
          <Typography
            variant="h5"
            sx={{
              ...commonTypographyProps,
              width: { xs: "100%", sm: "80%", md: "65%" },
              fontSize: { xs: "1rem", sm: "1rem", md: "1.1rem", xl: "1.2rem" },
              textAlign: { xs: "justify", sm: "left" },
              transition: " all 0.3s ease-in-out",
            }}
          >
            We are team of passionate designers and developers making Websites,
            Apps and Stuffs, we love to work with new ideas and new people.
          </Typography>
          <ButtonChip label="Work Catalogue" />
        </Stack>

        <Stack
          direction="row"
          spacing={3}
          sx={{
            ...commonTypographyProps,
            width: { xs: "100%", sm: "35%", md: "35%" },
            justifyContent: { xs: "center", sm: "flex-end" },
          }}
        >
          {renderCounter("Reach Out", counterRefs.reachOut)}
          {renderCounter("Designs", counterRefs.designs)}
          {renderCounter("Loves", counterRefs.loves)}
        </Stack>
      </Stack>
    </Container>
  );
};

export default Hero;
