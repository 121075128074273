import React from "react";
import {
  Card,
  Container,
  Typography,
  CardMedia,
  Stack,
} from "@mui/material";

const WorkArea = () => {
  const workArea = [
    {
      image: "/images/exper-icon/react.png",
    },
    {
      image: "/images/exper-icon/node.png",
    },
    {
      image: "/images/exper-icon/html2.png",
    },
    {
      image: "/images/exper-icon/css1.png",
    },
    {
        image: "/images/exper-icon/java.png",
      },
      {
        image: "/images/exper-icon/python.png",
      },
    {
      image: "/images/exper-icon/boot.png",
    },
    {
      image: "/images/exper-icon/mongo.png",
    },
    {
        image: "/images/exper-icon/mui.png",
      },
      {
        image: "/images/exper-icon/laravel.png",
      },
      {
        image: "/images/exper-icon/js.png",
      },
    {
      image: "/images/exper-icon/flutter.png",
    },
    {
      image: "/images/exper-icon/firebase.png",
    },
    {
      image: "/images/exper-icon/php.png",
    },
    {
      image: "/images/exper-icon/sql.png",
    },
  ];

  return (
    <React.Fragment>
      <Container
        maxWidth="xl"
        sx={{
          mb: 10,
        }}
      >
        <Typography
          variant="h2"
          align="center"
          mb={2}
          sx={{
            color: (theme) => theme.palette.primary.lightGreen,
            fontSize: {
              xs: "1.6rem",
              sm: "2rem",
              md: "2.2rem",
              lg: "2.6rem",
              xl: "3rem",
            },
            transition: "all 0.3s ease-in-out",
          }}
        >
          Work Area
        </Typography>

        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            width: {
              xs: "100%",
              sm: "80%",
              md: "70%",
              lg: "70%",
              xl: "50%",
            },
            mx: "auto",
            mb: 4,
          }}
        >
          Our work area is the guiding principles that dictate our behavior and
          actions. They are the foundation of our company culture.
        </Typography>

        <Stack direction="row" spacing={{ xs: 1, sm: 2 }} justifyContent="center" mb={4} useFlexGap flexWrap="wrap">
          {workArea.map((item, index) => (
            <Card
              key={index}
              variant="outlined"
              sx={{
                height: { xs: "80px", sm: "100px" },
                width: { xs: "80px", sm: "100px" },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: 1,
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",

                "&:hover": {
                  boxShadow: "rgba(0, 0, 0, 0.2) 0px 8px 24px;",
                  transform: "scale(1.05)",
                  transition: "all 0.3s ease-in-out",
                },
              }}
            >
              <CardMedia
                component="img"
                image={item.image}
                alt="work area"
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </Card>
          ))}
        </Stack>
      </Container>
    </React.Fragment>
  );
};

export default WorkArea;
