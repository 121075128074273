import React from "react";
import { styled, keyframes } from "@mui/system";
import { Typography } from "@mui/material";

const fall = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  50%, 80% {
    color: hsl(calc(var(--i) * 25), 100%, 50%);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
`;

const TxtCharacter = styled("span")(({ i }) => ({
  animation: `${fall} 3s ease-in-out infinite`,
  animationDelay: `calc(${i} * 200ms)`,
}));

const Txt = styled("div")({
  display: "flex",
  fontWeight: "bold",
  fontSize: "clamp(2rem, 13vw, 6rem)",
  textTransform: "uppercase",
  color: "#00BCA3",
});

const Page = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
 minHeight: "100vh",
  overflow: "hidden",
});

const FallingText = ({ text }) => {
  return (
    <Page>
      <Txt aria-label={text}>
        {text.split("").map((char, index) => (
          <TxtCharacter key={index} i={index}>
            {char}
          </TxtCharacter>
        ))}
      </Txt>
      <Typography variant="h4" color="textSecondary" align="center">
        We are loading the content for you...
      </Typography>
    </Page>
  );
};

export default FallingText;
