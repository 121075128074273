import React from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Container,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import blogPosts from "../data/blogData.json";

const BlogCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  "& .MuiCardMedia-root": {
    paddingTop: "56.25%", // 16:9 aspect ratio
  },
}));

const OurBlogs = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Container
        id="blogs"
        maxWidth="xl"
        sx={{
          mb: 10,
        }}
      >
        <Typography
          variant="h2"
          align="center"
          mb={2}
          sx={{
            color: (theme) => theme.palette.primary.lightGreen,
            fontSize: {
              xs: "1.6rem",
              sm: "2rem",
              md: "2.2rem",
              lg: "2.6rem",
              xl: "3rem",
            },
            transition: "all 0.3s ease-in-out",
          }}
        >
          Our Blogs
        </Typography>

        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            width: {
              xs: "100%",
              sm: "80%",
              md: "70%",
              lg: "70%",
              xl: "50%",
            },
            mx: "auto",
            mb: 4,
          }}
        >
          Explore our latest blogs on technology, business, and design. Stay up
          to date with the latest trends and insights.
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <BlogCard>
              <CardActionArea
                onClick={() => {
                  navigate(`/blog/${blogPosts[0].id}`);
                }}
              >
                <CardMedia
                  component="div"
                  image={blogPosts[0].image}
                  title={blogPosts[0].title}
                  sx={{
                    paddingTop: "75%",
                    transition: "transform 0.5s",
                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                  }} // Adjust aspect ratio for larger image
                />
                <CardContent
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: "rgba(0,0,0,0.5)",
                    color: "white",
                    padding: (theme) => theme.spacing(2),
                  }}
                >
                  <Typography gutterBottom variant="h4" noWrap>
                    {blogPosts[0].title}
                  </Typography>
                  <Typography variant="body1">
                    Author: {blogPosts[0].author} | {blogPosts[0].date}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </BlogCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              {blogPosts.slice(1).map((post) => (
                <Grid item key={post.id} xs={12} sm={6}>
                  <BlogCard>
                    <CardActionArea
                      onClick={() => {
                        navigate(`/blog/${post.id}`);
                      }}
                    >
                      <CardMedia
                        component="div"
                        image={post.image}
                        title={post.title}
                        //hover effect
                        sx={{
                          paddingTop: "75%",
                          transition: "transform 0.5s",
                          "&:hover": {
                            transform: "scale(1.1)",
                          },
                        }}
                      />
                      <CardContent
                        sx={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: "rgba(0,0,0,0.5)",
                          color: "white",
                          padding: (theme) => theme.spacing(2),
                        }}
                      >
                        <Typography gutterBottom variant="h4" noWrap>
                          {post.title}
                        </Typography>
                        <Typography variant="body1">
                          Author: {post.author} | {post.date}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </BlogCard>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default OurBlogs;
