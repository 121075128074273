import React, { useState, useEffect } from 'react';
import FallingText from './components/FallingText';
import Layout from './components/Layout';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BlogDetails from './components/BlogDetails';

const App = () => {
  const [theme, colorMode] = useMode();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading process, then hide the loader
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000); // Adjust the timeout as needed

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {loading ? (
          <FallingText text="Aikyam" />
        ) : (
          <Router>
            <Routes>
              <Route path="/" element={<Layout />} />
              <Route path="/blog/:id" element={<BlogDetails />} />
            </Routes>
          </Router>
        )}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
